/* eslint-disable react/no-unescaped-entities */
import * as React from 'react';
import { _, link, linkBig, linkSmall } from '@src/translations/utils.translations';
import {
  ButtonService,
  RowStart,
  SimpleLink,
  TextH3Medium,
  TextMedium,
  TextRegular,
  TextSecondaryRegular,
} from '@src/components';
import { reachGoalYaMetrika } from '@src/utils/metrika';
import { BoxberryAd } from '@src/pages/box/ward/BoxberryAd';
import { isDotCom } from '@src/host';

const qWithoutEmail = {
  question: _(
    'Зачем нужно указывать email? Можно ли участвовать в игре без него?',
    'Why is it necessary to provide an email? Is it possible to participate in the game without it?',
  ),
  search:
    'email account аккаунт registration регистрация letter письмо notification уведомление personal data личные данные register зарегистрироваться social network социальная сеть',
  answer: _(
    <>
      <p>
        Мы используем email, чтобы вы могли зарегистрироваться и входить на сайт под своим личным
        аккаунтом. Чтобы убедиться, что аккаунт создаете именно вы, нам необходимо отправить вам
        ссылку регистрации и самый простой способ сделать это — отправить письмо вам на email.
        Также, email используется для отправки уведомления о проведении жеребьевки.
      </p>
      <p>
        Кроме как для отправки уведомдений, мы не используем ваш почтовый адрес для чего-то еще и ни
        при каких условиях не передадим ваши личные данные в третьи руки. Пожалуйста, ознакомьтесь с{' '}
        {linkBig('/policy', 'политикой обработки персональных данных')}.
      </p>
      <p>
        Технически, вы все-таки можете зарегистрироваться без указания email адреса — если вы
        зайдете на сайт с помощью социальной сети, для которой не указывали email.
      </p>
    </>,
    <>
      <p>
        We use email so you can register and log in to the site under your personal account. To
        ensure that the account is being created by you, we need to send you a registration link,
        and the simplest way to do this is to send an email to your email address. Also, email is
        used to send notifications about the draw.
      </p>
      <p>
        Other than for sending notifications, we do not use your email address for anything else and
        will not under any circumstances pass your personal data to third parties. Please
        familiarize yourself with our
        {linkBig('/policy', 'personal data processing policy')}.
      </p>
      <p>
        Technically, you can still register without providing an email address — if you log in to
        the site using a social network for which you did not provide an email.
      </p>
    </>,
  ),
};

const qNoEmailInbox = {
  question: _(
    'Мне на почту не доходят письма, что делать?',
    'I am not receiving emails, what should I do?',
  ),
  search:
    ' emails email receiv sent пришли пись письм письма прих приходят дошли писем пришло приш письмо приходит дошло дошл',
  answer: _(
    <>
      <p>
        Убедитесь, что письмо случайно не попало в папку «спам», «рассылки» или любую другую папку в
        вашем почтовом агенте. Поищите письмо по отправителю <b>postman@santa-secret.ru</b> Если
        письмо все-таки дошло и попало в спам, пожалуйста, пометьте его как «не спам» и по
        возможности добавьте адрес <i>postman@santa-secret.ru</i> в доверительные.
      </p>
      <p>
        Если вы участвуете в жеребьевке со своими коллегами и никому из вас не пришло письмо, то
        вероятнее всего, система безопасности в вашей фирме блокирует письма с нашего сайта. Вам
        необходимо обратиться в IT отдел и попросить, чтобы они временно разрешили присылать письма
        с сайта santa-secret.ru.
      </p>
      <p>
        Некоторые почтовые сервисы, в частности <b>outlook</b> и <b>icloud</b>, имеют очень строгие
        настройки спама и могут отклонять письма с нашего сайта. Желательно использовать адрес
        другого сервиса, если такой у вас имеется.
      </p>
      <p>
        Почтовое уведомление необходимо только в случае регистрации или смены пароля, вся остальная
        информация доступна прямо на сайте и все уведомления отображаются в специальной секции.
      </p>
      <p>
        Попробуйте использовать социальные сети для входа и регистрации, а так же, включите
        уведомления через Vkontakte или Telegram на странице аккаунта, чтобы получать информацию с
        сайта без использования email.
      </p>
      <p>
        Если ничего из вышеперечисленного не относится к вашей ситуации или вы сделали все возможное
        с вашей стороны, напишите на postman@santa-secret.ru с детальным описанием проблемы и всеми
        email адресами, письма до которых не дошли. Убедитесь, что все адреса указаны верно. Также,
        пришлите информацию о том была ли это "Быстрая Жеребьевка" или вы участвуете в коробке.
      </p>
    </>,
    <>
      <p>
        Make sure that the email did not accidentally end up in the "spam", "promotions", or any
        other folder in your email client. Search for the email by the sender{' '}
        <b>postman@santa-secret.ru</b> If the email did arrive and went to spam, please mark it as
        "not spam" and, if possible, add the address <i>postman@santa-secret.ru</i> to your trusted
        contacts.
      </p>
      <p>
        If you are participating in the draw with your colleagues and none of you received the
        email, then most likely, your company's security system is blocking emails from our site.
        You will need to contact your IT department and ask them to temporarily allow emails from
        the site santa-secret.ru.
      </p>
      <p>
        Some email services, especially <b>outlook</b> and <b>icloud</b>, have very strict spam
        settings and may reject emails from our site. It's preferable to use an address from another
        service, if you have one.
      </p>
      <p>
        Email notification is only necessary in the case of registration or password change, all
        other information is available directly on the site and all notifications are displayed in a
        special section.
      </p>
      <p>
        Try using social networks for login and registration, and also enable notifications through
        Vkontakte or Telegram on your account page, to receive information from the site without
        using email.
      </p>
      <p>
        If none of the above applies to your situation or you have done everything possible on your
        end, write to postman@santa-secret.ru with a detailed description of the problem and all
        email addresses to which the emails did not reach. Make sure that all addresses are
        correctly entered. Also, send information on whether it was a "Quick Draw" or if you are
        participating in a box.
      </p>
    </>,
  ),
};

const qNoEmailInboxOrg = {
  question: _(
    'После отправления приглашений участникам не дошли письма, что делать?',
    'What to do if the invitation emails did not reach the participants after being sent?',
  ),
  search:
    ' emails email receiv sent пришли пись письм письма прих приходят дошли писем пришло приш письмо приходит дошло дошл',
  answer: _(
    <>
      <p>
        Если никому из участнков в вашей коробки не дошли письма приглашений, то это может означать
        одну из двух ситуаций: 1 - возможно вы выставили опцию "Отправить приглашения вместе с
        результатами жеребьевки", когда приглашали участников, в таком случае, письма участникам
        придут только после того, как вы проведете жеребьевку. 2 - если вы участвуете в жеребьевке
        со своими коллегами и никому из вас не пришло письмо, то, вероятнее всего, система
        безопасности в вашей фирме блокирует письма с нашего сайта. Вам необходимо обратиться в IT
        отдел и попросить, чтобы они временно разрешили присылать письма с сайта santa-secret.ru.
      </p>
      <p>
        Убедитесь, что участники проверили папку «спам», «рассылки» и все остальные папки в почтовом
        агенте. Если письмо все-таки дошло и попало в спам, пожалуйста, пометьте его как «не спам» и
        по возможности добавьте адрес <i>postman@santa-secret.ru</i> в доверительные.
      </p>
      <p>
        Некоторые почтовые сервисы, в частности <b>outlook</b> и <b>icloud</b>, имеют очень строгие
        настройки спама и могут отклонять письма с нашего сайта. Желательно использовать адрес
        другого сервиса, если такой у вас имеется.
      </p>
      <p>
        Почтовое уведомление необходимо только в случае регистрации или смены пароля, вся остальная
        информация доступна прямо на сайте и все уведомления отображаются в специальной секции.
      </p>
      <p>
        Если ничего из вышеперечисленного не относится к вашей ситуации или вы сделали все возможное
        с вашей стороны, напишите на postman@santa-secret.ru с детальным описанием проблемы и всеми
        email адресами, письма до которых не дошли. Убедитесь, что все адреса указаны верно. Также,
        пришлите информацию о том была ли это "Быстрая Жеребьевка" или вы создавали коробку.
      </p>
    </>,
    <>
      <p>
        If none of the participants in your box received the invitation emails, it could mean one of
        two situations: 1 - you might have set the option "Send invitations along with draw results"
        when inviting participants, in which case, the emails will only be sent after you conduct
        the draw. 2 - if you are participating in the draw with your colleagues and none of you
        received an email, then, most likely, the security system in your company is blocking emails
        from our site. You will need to contact your IT department and ask them to temporarily allow
        emails from the site santa-secret.ru.
      </p>
      <p>
        Make sure that participants have checked the "spam", "promotions", and all other folders in
        their email client. If the email did arrive and ended up in spam, please mark it as "not
        spam" and, if possible, add the address <i>postman@santa-secret.ru</i> to your trusted
        contacts.
      </p>
      <p>
        Some email services, especially <b>outlook</b> and <b>icloud</b>, have very strict spam
        settings and may reject emails from our site. It is advisable to use an address from another
        service, if you have one.
      </p>
      <p>
        Email notification is only necessary in the case of registration or password change, all
        other information is available directly on the site and all notifications are displayed in a
        special section.
      </p>
      <p>
        If none of the above applies to your situation or you have done everything possible on your
        end, write to postman@santa-secret.ru with a detailed description of the problem and all
        email addresses to which the emails did not reach. Make sure that all addresses are
        correctly entered. Also, send information on whether it was a "Quick Draw" or if you created
        a box.
      </p>
    </>,
  ),
};

const qWhenIWillKnowWard = {
  question: _(
    'Я заполнил свою карточку для участия в игре. Когда я узнаю, кому дарить подарок?',
    'I filled out my card to participate in the game. When will I know whom to give a gift to?',
  ),
  search: 'when draw conduct когда будет жеребьевка кому дарить подарок подопечный',
  answer: _(
    <>
      Мы не проводим жеребьевку автоматически, это делает ваш организатор коробки. Как только ваш
      организатор проведет жеребьевку, вам на почту придет уведомление и вы сможете увидеть имя
      подопечного.
    </>,
    <>
      The draw is not conducted automatically; it is done by the organizer of your box. As soon as
      your organizer conducts the draw, you will receive a notification via email, and you will be
      able to see the name of your recipient.
    </>,
  ),
};

const qOddNumber = {
  question: _(
    'Работает ли жеребьевка для нечетного количества участников? Никто не останется без подарка?',
    'Does the draw work for an odd number of participants? Will no one be left without a gift?',
  ),
  search:
    'нечетное количество участ участников четное количество участников odd number of participants even number of participants',
  answer: _(
    <>
      Да, работает! Нечетное количество ничем, в данном случае, не отличается от четного. Покажем на
      примере троих людей. Допустим, в жеребьевке участвуют Маша, Вася и Петя, тогда после
      жеребьевки одним из возможных вариантов распределения будет: Маша дарит Васе, Вася дарит Пете,
      а Петя дарит Маше. Как видите, все будут с подарками!
    </>,
    <>
      Yes, it works! An odd number of participants does not differ from an even number in this case.
      Let's illustrate with three people. Suppose Masha, Vasya, and Petya participate in the draw,
      then one of the possible distributions after the draw could be: Masha gifts to Vasya, Vasya
      gifts to Petya, and Petya gifts to Masha. As you can see, everyone will have gifts!
    </>,
  ),
};

const qWhatIsSanta = {
  question: _('Что такое «Тайный Санта?»', 'What is "Secret Santa"?'),
  search: 'анонимный обмен подарками организатор anonymous gift exchange organizer',
  answer: _(
    <>
      «Тайный Сантa» — популярная церемония анонимного обмена подарками. Это новогодняя игра с
      простыми правилами: каждый участник является Тайным Сантой для одного из остальных участников
      и втайне готовит для него подарок. В результате всем достаётся и радость подготовки сюрприза,
      и подарок. <br />
      Данный сайт — сервис, который помогает огранизовать эту игру онлайн.
    </>,
    <>
      "Secret Santa" is a popular ceremony of anonymous gift exchange. It's a New Year's game with
      simple rules: each participant is a Secret Santa for one of the other participants and
      secretly prepares a gift for them. As a result, everyone gets the joy of preparing a surprise
      and receiving a gift. <br />
      This website is a service that helps organize this game online.
    </>,
  ),
};

const qWhatIsThisSiteFor = {
  question: _('Зачем и для кого был создан этот сайт?', 'Why and for whom was this site created?'),
  search: 'бумажки бумажка шапка шляпа онлайн pieces of paper hat online',
  answer: _(
    <>
      Обычно жеребьёвка на «Тайного Санту» происходит с помощью бумажек с именами участников —
      каждый человек из группы вытаскивает бумажку с именем своего подопечного из какой-нибудь шляпы
      или <i>коробки</i>. В итоге, каждому из участников достается случайный подопечный. И хотя
      данный способ весел и волнителен, он имеет пару неудобств: во-первых, всем участникам
      необходимо заранее собраться в одном месте для проведения жеребьевки, а во-вторых, есть
      вероятность того, что последний участник вытащит свое имя и жеребьевку придется переделывать.
      <br />
      <br />
      Однажды, создатель сайта хотел сыграть с друзьями в «Тайного Санту», но возможности собраться
      всем вместе заранее для жеребьевки не было, тогда и был создан этот сервис.
      <br />
      <br />
      Этот сервис создан для тех, кто хочет огранизовать игру в «Тайного Санту» между своими
      друзьями, коллегами или любыми другими группами людей.
    </>,
    <>
      Usually, the draw for "Secret Santa" is conducted using pieces of paper with the names of
      participants — each person from the group draws a piece of paper with the name of their
      recipient from a hat or a <i>box</i>. As a result, each participant gets a random recipient.
      Although this method is fun and exciting, it has a couple of inconveniences: first, all
      participants need to gather in one place beforehand for the draw, and second, there is a
      chance that the last participant will draw their own name, requiring a redo of the draw.
      <br />
      <br />
      Once, the creator of the site wanted to play "Secret Santa" with friends, but there was no
      opportunity to gather everyone together in advance for the draw, so this service was created.
      <br />
      <br />
      This service is created for those who want to organize a "Secret Santa" game among their
      friends, colleagues, or any other groups of people.
    </>,
  ),
};

const qWhatIsTheBox = {
  question: _('Что означает «коробка»?', 'What does "box" mean?'),
  search: 'такое коробка коробки what box boxes',
  answer: _(
    <>
      Обычно для жеребьёвки на «Тайного Санту» используют чью-нибудь шляпу, пакет или <b>коробку</b>
      , в которую все участники скидывают свои имена.
      <br />
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      Коробка на этом сайте представляет собой место, куда участники игры "сбрасывают" свои карточки
      с именами для участия и где происходит жеребьевка.
    </>,
    <>
      Usually, for the "Secret Santa" draw, someone's hat, bag, or a <b>box</b> is used, into which
      all participants drop their names.
      <br />
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      The box on this site represents a place where game participants "drop off" their cards with
      names for participation and where the draw takes place.
    </>,
  ),
};

const qWhoIsOrganizer = {
  question: _('Кто такой «организатор коробки»?', 'Who is the "box organizer"?'),
  search: 'связаться организатором огранизатор contact the organizer organizer',
  answer: _(
    <>
      Организатор — человек, который организовывает онлайн игру между группой людей в конкретной
      коробке. Он приглашает участников, проводит жеребьевку и следит за проведением игры.
      <br />
      На странице каждой коробки сверху указано имя организатора со ссылкой на его почтовый адрес, в
      случае если у вас есть к нему вопрос по игре.
    </>,
    <>
      The organizer is the person who organizes the online game among a group of people in a
      specific box. They invite participants, conduct the draw, and oversee the game's progress.
      <br />
      On the page of each box, the name of the organizer is listed at the top with a link to their
      email address, in case you have any game-related questions for them.
    </>,
  ),
};

const qHowToPlay = {
  question: _(
    'Как организовать «Тайного Санту» с помощью этого сайта?',
    'How to organize "Secret Santa" using this site?',
  ),
  search: 'как создать игру how to create a game',
  answer: _(
    <>
      <b>Шаг 1.</b> Создайте {linkBig('/box/new', 'Коробку')}, указав необходимые настройки —
      название, ограничение стоимости подарка, необходимость указывания почтового адреса для
      участников и другие.
      <br />
      <br />
      <b>Шаг 2.</b> Пригласите участников. Это можно сделать двумя способами — ввести их имена и
      email адреса самостоятельно через специальную таблицу приглашения или отправить участникам
      ссылку-приглашение, по которой они смогут создать себе карточку самостоятельно.
      <br />
      Если вы сами планируете принимать участие в игре — создайте себе карточку участника.
      <br />
      <br />
      <b>Шаг 3.</b> Подождите, пока все участники зарегистрируются и проведите жеребьевку (вы можете
      провести жеребьевку не дожидаясь подтверждения всех участников). Настройки коробки и
      жеребьевка доступны только вам, как организатору. При желании, вы можете регулировать
      жеребьевку — например, пометить карточки, чтобы какие-то участники не попадались друг другу
      или сделать так, чтобы жеребьевка проходила по кругу, то есть не было ситуации, когда два
      участника дарят подарок друг другу.
      <br />
      <br />
      <b>Шаг 4.</b> Следите за игрой и помогайте игрокам с возникающими вопросами. На странице с
      участниками будет показано, кто подтвердил участие, кто уже знает своего подопечного, кто ждет
      подарок, а кто его уже получил. При необходимости, вы сможете посмотреть, кто является Тайным
      Сантой для другого, чтобы проконтролировать процесс (сделать это можно на странице "Кто чей
      Санта? Таблица участников" через настройки коробки).
    </>,
    <>
      <b>Step 1.</b> Create a {linkBig('/box/new', 'Box')}, specifying the necessary settings —
      name, gift price limit, the necessity of providing postal addresses for participants, and
      others.
      <br />
      <br />
      <b>Step 2.</b> Invite participants. This can be done in two ways — enter their names and email
      addresses yourself through a special invitation table or send participants an invitation link,
      which they can use to create their own card.
      <br />
      If you plan to participate in the game yourself — create your own participant card.
      <br />
      <br />
      <b>Step 3.</b> Wait until all participants register and conduct the draw (you can conduct the
      draw without waiting for confirmation from all participants). Settings for the box and the
      draw are available only to you as the organizer. If desired, you can adjust the draw — for
      example, mark cards so that certain participants do not get each other or make the draw go in
      a circle, i.e., avoiding a situation where two participants give gifts to each other.
      <br />
      <br />
      <b>Step 4.</b> Monitor the game and assist players with any arising questions. On the
      participants' page, it will be shown who confirmed their participation, who already knows
      their recipient, who is waiting for a gift, and who has already received one. If necessary,
      you can check who is the Secret Santa for another to control the process (this can be done on
      the "Who is whose Santa? Participants' table" through the box settings).
    </>,
  ),
};

const qBoxLimit = {
  question: _(
    'Есть ли ограничение на количество участников в коробке?',
    'Is there a limit on the number of participants in a box?',
  ),
  search:
    'сколько участников максимальное максимум ограничение how many participants maximum limit',
  answer: _(
    'Ограничения на количество участников в коробке нет',
    'There is no limit on the number of participants in the box draw',
  ),
};

const qCanIDrawWithoutRegister = {
  question: _(
    'Можно ли провести жеребьевку без создания коробки и регистрации участников?',
    'Is it possible to conduct a draw without creating a box and registering participants?',
  ),
  search: 'быстрая жеребьевка регистрации без аккаунта quick draw without registration no account',
  answer: _(
    <>
      Да. Если вы не хотите создавать коробку и тратить время на регистрацию игроков, вы можете
      провести {linkBig('/randomizer', 'Быструю Жеребьевку')}, указав только имена и email адреса в
      таблице — участникам на почту придут уведомления с именами их подопечных, а вам, как
      организатору, отправится ссылка на страницу с результатами жеребьевки. <br />
      <br />
      Этот способ лишает участников возможности указать пожелания и связываться со своим подопечным,
      а организатор не может настроить жеребьевку.
      <p>
        Органичение на участие в быстрой жеребьевке - <b>100 человек</b>
      </p>
    </>,
    <>
      Yes. If you do not want to create a box and spend time on registering players, you can conduct
      a {linkBig('/randomizer', 'Quick Draw')}, by only specifying names and email addresses in a
      table — participants will receive notifications with the names of their recipients by email,
      and you, as the organizer, will receive a link to the page with the draw results. <br />
      <br />
      This method deprives participants of the opportunity to specify their wishes and communicate
      with their recipient, and the organizer cannot adjust the draw.
      <p>
        The limit for participation in a quick draw is <b>100 people</b>
      </p>
    </>,
  ),
};

const qMoney = {
  question: _('Это бесплатный сервис?', 'Is this a free service?'),
  search: 'оплата сколько бесплатн стоит цена стоимость payment how much does it cost price',
  answer: _(
    <>
      Сайт бесплатный для всех, независимо от количества участников.
      <br />
      <br />
      Создателю сайта будет очень приятно, если вы поддержите развитие сервиса материально, так как
      на его содержание уходят средства и много времени. Поддержать проект можно на{' '}
      {linkBig('/donate', 'этой странице')}. Спасибо!
    </>,
    <>
      The site is free for everyone, regardless of the number of participants.
      <br />
      <br />
      The site creator would be very grateful for financial support to help with the service's
      development, as maintaining it requires funds and a lot of time. You can support the project
      on {linkBig('/donate', 'this page')}. Thank you!
    </>,
  ),
};

const qMinUsers = {
  question: _(
    'Сколько участников минимально необходимо для проведения жеребьевки?',
    'What is the minimum number of participants required for the draw?',
  ),
  search: 'минимум участников минимальное количество minimum participants minimum number',
  answer: _(
    'Для того чтобы жеребьевка была хоть немного секретной, необходимо, как минимум, три участника.',
    'For the draw to be somewhat secretive, a minimum of three participants is required.',
  ),
};

const qOrgCanParticipate = {
  question: _(
    'Могу ли я, как организатор, тоже участвовать в жеребьевке и не знать, кто кому попался?',
    'As an organizer, can I also participate in the draw and not know who got whom?',
  ),
  search: 'участие организатор participation organizer',
  answer: _(
    <>
      Да, вы можете быть как организатором, так и участником в коробке.
      <br />У вас, как у организатора, есть возможность посмотреть, кто кому попался в качестве
      тайного санты или подопечного. Для этого вам нужно перейти на страницу "Кто чей Санта? Таблица
      участников". Но даже если вы перейдете на страницу, вы не увидите подопечных до тех пор, пока
      сами не кликните на соответствующую кнопку, таким образом, если вы не хотите знать, кто кому
      попался в качестве Тайного Санты, просто не нажимайте на "Узнать подопечного" или "Узнать
      тайного Санту" напротив участника в таблице :)
    </>,
    <>
      Yes, you can be both the organizer and a participant in the box.
      <br />
      As an organizer, you have the ability to see who got whom as a Secret Santa or recipient. To
      do this, you need to go to the "Who is whose Santa? Participants' Table" page. However, even
      if you go to this page, you won't see the recipients until you click on the corresponding
      button. Thus, if you don’t want to know who got whom as a Secret Santa, simply do not press
      "Find out the recipient" or "Find out the Secret Santa" next to a participant in the table :)
    </>,
  ),
};

const qDownloadExcel = {
  question: _(
    'Можно ли выгрузить таблицу участников со всей информацией в excel?',
    "Can I download the participants' table with all the information in Excel?",
  ),
  search: 'таблица excel csv table',
  answer: _(
    'Да. Вы можете скачать такую таблицу со страницы "Кто чей Санта? Таблица участников."',
    'Yes. You can download such a table from the "Who is whose Santa? Participants\' Table" page.',
  ),
};

const qHowToKnowSantas = {
  question: _(
    'Как посмотреть, кто кому попался в качестве Тайного Санты?',
    'How can I see who got whom as a Secret Santa?',
  ),
  search: 'чей санта секрет вижу настроек whose santa secret see settings',
  answer: _(
    <>
      Чтобы посмотреть информацию об участниках, перейдите на страницу "Кто чей Санта? Таблица
      участников" через меню организатора. Для перехода в меню организатора, нажмите на иконку
      шестеренки справа в шапке коробки. Если вы не видите иконки шестеренки, но вы организатор, то
      это значит, что вы зашли на сайт не под тем аккаунтом, с которым создавали коробку. На
      странице каждой коробки сверху указано имя организатора со ссылкой на его почтовый адрес.
      Кликните на имя и почтовый адрес скопируется. Таким образом вы можете узнать, какой именно
      адрес был использован для создания коробки.
    </>,
    <>
      To view information about participants, go to the "Who is whose Santa? Participants' Table"
      page through the organizer's menu. To access the organizer's menu, click on the gear icon on
      the right in the header of the box. If you do not see the gear icon but you are the organizer,
      it means that you have logged into the site with a different account than the one used to
      create the box. The name of the organizer is listed at the top of each box's page with a link
      to their email address. Click on the name and the email address will be copied. This way you
      can find out which specific address was used to create the box.
    </>,
  ),
};

const qOrgCanSetupDraw = {
  question: _(
    'Как настроить жеребьевку, чтобы участники не попадались друг другу? Как указать подопечного вручную?',
    "How can I set up the draw so that participants don't get each other? How can I manually assign a recipient?",
  ),
  search:
    'настроить жена муж супруги парень девушке указать подопечных попался другому вижу настроек assign set up spouse boyfriend girlfriend recipients got each other see settings',
  answer: _(
    <>
      Чтобы настроить жеребьевку перейдите на страницу «Жеребьевка» через меню организатора и
      включите «Расширенные настройки» ー вы увидите возможные варианты настройки жеребьевки. Вы
      можете как выбрать подопечных самостоятельно, так и пометить какие-то из карточек цветом,
      чтобы они не попались друг другу. Остальные карточки будут распределены случайным образом.
      Например, чтобы настроить жеребьевку таким образом, чтобы супруги не дарили подарки друг
      другу, пометьте каждую пару одинаковым цветом. Карточки с одинаковыми цветами не попадутся
      друг другу при жеребьевке.
      <br />
      Также, вы можете поставить опцию «Круговая жеребьевка», исключающую ситуацию, при которой двое
      участников дарят подарки друг другу, то есть участники будут дарить подарки как бы по кругу.
      <br />
      <br />
      <b>Участники НЕ узнают о том, что вы настроили жеребьевку каким-либо образом.</b>
    </>,
    <>
      To set up the draw, go to the "Draw" page through the organizer's menu and enable "Advanced
      settings" — you will see possible options for setting up the draw. You can either choose
      recipients manually or mark some of the cards with a color, so they do not get each other. The
      rest of the cards will be distributed randomly. For example, to set up the draw so that
      spouses do not give gifts to each other, mark each pair with the same color. Cards with the
      same colors will not get each other in the draw.
      <br />
      Also, you can enable the "Circular Draw" option, which excludes the situation where two
      participants give gifts to each other, meaning participants will give gifts in a circular
      fashion.
      <br />
      <br />
      <b>Participants will NOT know that you have set up the draw in any particular way.</b>
    </>,
  ),
};

const qOrgCannotSeeSettings = {
  question: _(
    'Я организатор коробки, но не вижу кнопки "провести жеребьевку", что делать?',
    'I am the organizer of the box, but I don\'t see the "conduct the draw" button, what should I do?',
  ),
  search: 'вижу настроек настроек где жеребьевка see settings settings where draw',
  answer: _(
    <>
      <p>
        Такая ситуация может возникнуть у тех, кто создавал коробку под одним аккаунтом, а
        зарегистрировался как участник под другим и забыл об этом. Вам необходимо зайти в коробку
        под тем аккаунтом, под которым вы создавали коробку, и тогда вы увидите меню для
        организатора.
      </p>
      <p>
        На странице каждой коробки сверху указано имя организатора со ссылкой на его почтовый адрес.
        Кликните на имя и почтовый адрес скопируется. Таким образом вы можете узнать, какой именно
        адрес был использован для создания коробки.
      </p>
    </>,
    <>
      <p>
        This situation can occur for those who created the box under one account but registered as a
        participant under another and forgot about it. You need to enter the box under the account
        with which you created the box, and then you will see the menu for the organizer.
      </p>
      <p>
        The name of the organizer is listed at the top of each box's page with a link to their email
        address. Click on the name and the email address will be copied. This way you can find out
        which specific address was used to create the box.
      </p>
    </>,
  ),
};

const qAddUsers = {
  question: _(
    'Как добавить еще участников? Можно ли добавить участников после проведения жеребьевки?',
    'How do I add more participants? Can I add participants after the draw has been conducted?',
  ),
  search: 'добавить участника больше людей пригласить add participant more people invite',
  answer: _(
    <>
      Кликните на иконку шестерёнки в правом верхнем углу коробки и нажмите на элемент меню
      "Добавить участников".
      <br />
      <br />
      <b>Важно:</b> Если вы добавляете участников после проведения жеребьевки, то после приглашения
      участника(ов) перейдите на страницу жеребьевки и выберите подходящую для вас опцию жеребьевки
      с дополнительными участниками.
    </>,
    <>
      Click on the gear icon in the upper right corner of the box and press the menu item "Add
      participants".
      <br />
      <br />
      <b>Important:</b> If you are adding participants after conducting the draw, then after
      inviting participant(s) go to the draw page and choose the draw option that suits you with
      additional participants.
    </>,
  ),
};

const qEditDeleteUser = {
  question: _(
    'Как редактировать карточку участника? Как изменить email участника? Как удалить карточку участника?',
    "How can I edit a participant's card? How can I change a participant's email? How can I delete a participant's card?",
  ),
  search: 'обновить карточку удал удалить update card delete',
  answer: _(
    <>
      Кликните на карточку участника на странице с участниками или перейдите на страницу "Кто чей
      Санта? Таблица участников" через меню организатора и нажмите "редактировать".
      <br />
      Вы можете именить любую информацию об участнике, если это необходимо, либо отправить
      нотификацию игроку с просьбой обновить данные.
      <br />
      Чтобы удалить участника, прокрутите сраницу вниз до конца и следуйте инструкции.
    </>,
    <>
      Click on the participant's card on the participants' page or go to the "Who is whose Santa?
      Participants' Table" page through the organizer's menu and press "edit".
      <br />
      You can change any information about the participant if necessary, or send a notification to
      the player asking them to update their data.
      <br />
      To delete a participant, scroll down to the bottom of the page and follow the instructions.
    </>,
  ),
};

const qDeleteUserAfterDraw = {
  question: _(
    'Можно ли удалить участника после проведения жеребьевки? Как это сделать?',
    'Can I delete a participant after the draw has been conducted? How can I do this?',
  ),
  search: 'обновить карточку удал удалите удалить update card delete',
  answer: _(
    <>
      Да, вы можете удалить карточку участника даже после проведения жеребьевки. Чтобы удалить
      игрока, перейдите к его карточке, кликнув на нее на странице с участниками и прокрутите
      страницу вниз ー там будет возможность удаления.
      <br />
      <br />
      Тайному Санте удаленного участника достанется его подопечный, а в случае, когда Тайный Санта и
      подопечный дарят подарки друг другу, новый подопечный будет выбран случайным образом из
      оставшихся участников. Например, если жеребьевка выглядит следующим образом: Вася → Петя →
      Маша → Катя → Вася, то если Петя передумал участвовать, Васе достанется новый подопечный ー
      Маша. Тайному Санте удаленного участника придет уведомление о смене подопечного.
    </>,
    <>
      Yes, you can delete a participant's card even after the draw has been conducted. To delete a
      player, go to their card by clicking on it on the participants' page and scroll down — there
      will be an option to delete.
      <br />
      <br />
      The Secret Santa of the deleted participant will receive their recipient, and in the case
      where the Secret Santa and the recipient give gifts to each other, a new recipient will be
      chosen randomly from the remaining participants. For example, if the draw looks like this:
      Vasya → Petya → Masha → Katya → Vasya, then if Petya decides not to participate, Vasya will
      get a new recipient — Masha. The Secret Santa of the deleted participant will receive a
      notification about the change of recipient.
    </>,
  ),
};

const qMultipleBoxes = {
  question: _(
    'Можно ли участвовать в нескольких коробках?',
    'Can I participate in multiple boxes?',
  ),
  search: 'несколько коробок',
  answer: _(
    <>
      Да, вы можете принимать участие в неограниченном количестве игр, причем, вы можете быть как
      участником, так и организатором в разных коробках.
    </>,
    <>
      Yes, you can participate in an unlimited number of games, and you can be both a participant
      and an organizer in different boxes.
    </>,
  ),
};

const qAdminVsParticipantBoxes = {
  question: _(
    'Можно ли быть организатором в одной коробке, и участником в другой?',
    'Can I be an organizer in one box and a participant in another?',
  ),
  search: 'несколько коробок multiple boxes',
  answer: _(
    <>
      Да, вы можете организовать одну игру сами, а принимать участие в другой игре. Также вы можете
      быть одновременно и участником и организатором игры.
    </>,
    <>
      Yes, you can organize one game and participate in another game. Also, you can be both a
      participant and an organizer in a game simultaneously.
    </>,
  ),
};

const qWaitingConfirmation = {
  question: _(
    'Что означает "Ожидается подтверждение участия" на карточке участника?',
    'What does "Awaiting participation confirmation" mean on a participant\'s card?',
  ),
  search:
    'подтвердить email подтвердить емейл подтверждение подтв участ confirm email confirm email confirmation confirm participant',
  answer: _(
    <>
      Надпись "Ожидается подтверждение участия" на карточке участника появляется после того, как
      организатор пригласил участника по email, либо после того, как участник создал карточку по
      ссылке приглашения, зарегистрировавшись на сайте. Это значит, что участник должен{' '}
      <b>перейти по ссылке из письма</b> и <b>заполнить карточку участника</b>. Организатор не может
      сделать подтверждение за участника, однако, жеребьевка может быть проведена даже если в
      коробке есть неподтвержденные участники - они будут участвовать в жеребьевке и смогут
      подтвердить участие позже.
    </>,
    <>
      The inscription "Awaiting participation confirmation" on a participant's card appears after
      the organizer has invited the participant by email, or after the participant has created a
      card using the invitation link and registered on the site. This means that the participant
      needs to <b>follow the link from the email</b> and <b>fill out the participant's card</b>. The
      organizer cannot confirm participation on behalf of the participant; however, the draw can be
      conducted even if there are unconfirmed participants in the box - they will participate in the
      draw and can confirm their participation later.
    </>,
  ),
};

const qHowToChangeCard = {
  question: _(
    'Как редактировать картинку, пожелания и адрес в моей карточке? Можно ли менять пожелания после проведения жеребьевки?',
    'How can I edit the picture, wishes, and address in my card? Can I change my wishes after the draw has been conducted?',
  ),
  search:
    'обновить карточку как можно менять увидит изменения update card how can change will see changes',
  answer: _(
    <>
      Вы можете менять информацию в своей карточке когда угодно. Если жеребьевка уже была проведена
      вашему Тайному Санте придет уведомление об изменении данных.
      <br /> Чтобы обновить информацию, перейдите на вкладку "Моя карточка" в коробке и кликните на
      иконку редактирования в верхнем правом углу вашей карточки ー вы перейдете на страницу, где
      сможете поменять информацию в карточке.
    </>,
    <>
      You can change the information in your card at any time. If the draw has already been
      conducted your Secret Santa will receive a notification about the data change.
      <br /> To update the information, go to the "My Card" tab in the box and click on the editing
      icon in the upper right corner of your card — you will go to a page where you can change the
      information in the card.
    </>,
  ),
};

const qNoPostalAddress = {
  question: _(
    'Я не вижу почтового адреса своего подопечного, почему?',
    'I do not see the postal address of my recipient, why?',
  ),
  search:
    'где адрес почтовый куда отправлять подарок какой адрес where is address postal where to send gift which address',
  answer: _(
    <>
      Вы можете не видеть адреса подопечного по двум причинам:
      <ul>
        <li>
          Ваш подопечный пока что не заполнил свою карточку. В этом случае на карточке будет
          написано, что адрес не был заполнен. Вы можете поторопить подопечного в чате.
        </li>
        <li>
          Организатор коробки не выставил опцию "Почтовый адрес" для того, чтобы у участников была
          возможность указать адрес. В этом случае, и у вас в карточке во вкладке "Моя карточка"
          адрес не будет отображаться. Обратитесь к своему организатору и попросите выставить опцию.
        </li>
      </ul>
    </>,
    <>
      You may not see your recipient's address for two reasons:
      <ul>
        <li>
          Your recipient has not yet filled out their card. In this case, the card will state that
          the address has not been filled in. You can hurry your recipient in the chat.
        </li>
        <li>
          The organizer of the box did not set the "Postal Address" option so that participants
          would have the opportunity to specify an address. In this case, your card in the "My Card"
          tab will also not display an address. Contact your organizer and ask them to set the
          option.
        </li>
      </ul>
    </>,
  ),
};

const qWillIKnowMySanta = {
  question: _(
    'Может ли участник узнать, кто его Тайный Санта?',
    'Can a participant find out who their Secret Santa is?',
  ),
  search: 'узнать санту секрет find out santa secret',
  answer: _(
    <>
      Участник может узнать, кто его Тайный Санта, только в двух случаях: если организатор сообщит
      ему об этом или если Тайный Санта сам выдаст себя через чат (сообщит свое имя намеренно) или
      иным образом.
    </>,
    <>
      A participant can find out who their Secret Santa is only in two cases: if the organizer tells
      them or if the Secret Santa reveals themselves through chat (intentionally disclosing their
      identity) or in some other way.
    </>,
  ),
};

const qCanSantaBeWard = {
  question: _(
    'Может ли случиться так, что Тайный Санта и подопечный дарят подарок друг другу?',
    'Can it happen that the Secret Santa and the recipient give gifts to each other?',
  ),
  search: 'друг другу дарят санта подопечный give each other gifts santa recipient',
  answer: _(
    <>
      Да, такое распределение жеребьевки имеет место быть. Однако, организатор коробки может
      выставить опцию "Круговая жеребьевка" на странице жеребьевки, чтобы такой ситуации не
      произошло.
    </>,
    <>
      Yes, such a distribution in the draw is possible. However, the organizer of the box can set
      the option "Circular Draw" on the draw page to prevent such a situation.
    </>,
  ),
};

const qChangedMyMind = {
  question: _(
    'Я передумал(а) участвовать в игре, как можно удалить карточку?',
    'I changed my mind about participating in the game, how can I delete my card?',
  ),
  search: 'хочу удал удалить удалите карточку как want to delete card how',
  answer: _(
    <>
      Вы можете удалить свою карточку самостоятельно, если жеребьевка в коробке еще не была
      проведена. Для этого перейдите на страницу "Моя карточка" в коробке, нажмите на иконку
      редактирования и прокрутите страницу вниз ー вы увидите опцию удаления.
      <br />
      <br />
      Если жеребьевка уже была проведена, то самостоятельно выйти из игры вы не сможете ー
      обратитесь к организатору коробки и попросите его удалить вашу карточку. Организатор указан
      слева сверху, рядом с картинкой коробки, кликните на его имя и скопируется email адрес, по
      которому можно связаться с ним.
    </>,
    <>
      You can delete your card yourself if the draw in the box has not yet been conducted. To do
      this, go to the "My Card" page in the box, click on the editing icon, and scroll down — you
      will see the option to delete.
      <br />
      <br />
      If the draw has already been conducted, you cannot exit the game on your own — contact the
      organizer of the box and ask them to delete your card. The organizer is listed at the top
      left, next to the box picture, click on their name and the email address will be copied, which
      you can use to contact them.
    </>,
  ),
};

const qWhoToAskQuestions = {
  question: _(
    'К кому обращаться с вопросами по игре?',
    'Who should I contact with questions about the game?',
  ),
  search:
    'задать вопрос кому когда жеребьевка куда отдать подарок положить под елку передумал участвовать где празднуем ask question whom when draw where give gift put under tree changed mind participating where celebrate',
  answer: _(
    <>
      Если у вас есть вопрос по конкретной игре, а не по работе сайта, то обращайтесь к организатору
      коробки. Организатор указан слева сверху на странице коробки, рядом с картинкой. Кликните на
      его имя и скопируется email адрес, по которому можно связаться с ним. Примеры вопросов, на
      которые может ответить организатор:
      <ul>
        <li>Когда мы будем дарить подарки?</li>
        <li>
          Подскажите, как будет происходить вручение подарка? Пересыл по внутренней почте между
          офисами или сторонней доставкой за свой счет?
        </li>
        <li>Меня не будет в офисе 25 декабря, куда мне положить подарок?</li>
        <li>Я передумал участвовать в игре, пожалуйста, удалите мою карточку</li>
        <li>Здравствуйте, я хочу отменить свое участие в тайном санте</li>
        <li>А когда нужно принести подарок или когда планируется вручение?)</li>
      </ul>
    </>,
    <>
      If you have a question about a specific game, not about the website's functionality, you
      should contact the organizer of the box. The organizer is listed at the top left on the box
      page, next to the picture. Click on their name and the email address will be copied, which you
      can use to contact them. Examples of questions the organizer can answer:
      <ul>
        <li>When will we be giving gifts?</li>
        <li>
          Could you tell me how the gift presentation will take place? Will it be through internal
          mail between offices or with external delivery at our own expense?
        </li>
        <li>I won't be in the office on December 25th, where should I leave the gift?</li>
        <li>I changed my mind about participating in the game, please delete my card</li>
        <li>Hello, I want to cancel my participation in Secret Santa</li>
        <li>And when do we need to bring the gift or when is the presentation planned?)</li>
      </ul>
    </>,
  ),
};

const qChatAnonymous = {
  question: _('Анонимный ли чат с подопечным?', 'Is the chat with the recipient anonymous?'),
  search: 'узнает санта подопечный анонимный чат сообщения диалог anonymous chat messages dialogue',
  answer: _(
    'Да, чат с подопечным анонимный, он не увидит вашего имени или аватарки.',
    'Yes, the chat with the recipient is anonymous; they will not see your name or avatar.',
  ),
};

const qDrawAnonymous = {
  question: _(
    'Если настроить жеребьевку вручную, участники узнают об этом?',
    'If I manually set up the draw, will the participants know about it?',
  ),
  search:
    'секретная настройка настрои указ указать вручную самостоят самостоятельно secret setting manually independently',
  answer: _(
    'Нет, участники не узнают, настроили ли вы жеребьевку или она была полностью случайной.',
    'No, participants will not know whether you set up the draw or if it was completely random.',
  ),
};

const qAdditionalDraw = {
  question: _(
    'Как добавить еще участников после проведения жеребьевки? Как провести дополнительную жеребьевку между новыми участниками?',
    'How can I add more participants after the draw has been conducted? How to conduct an additional draw among the new participants?',
  ),
  search: 'пригласить добавить больше участников invite add more participants',
  answer: _(
    <>
      После проведения жеребьевки, вы все ещё можете добавить новых участников - сделать это можно
      только через таблицу приглашения на странице добавления участников. Участники не могут
      добавиться в коробку самостоятельно после проведения жеребьевки. После того как вы добавите
      новых участников, перейдите на страницу жеребьевки и вы увидите возможность проведения
      дополнительной жеребьевки.
    </>,
    <>
      After the draw has been conducted, you can still add new participants - this can only be done
      through the invitation table on the page for adding participants. Participants cannot add
      themselves to the box independently after the draw has been conducted. After you add new
      participants, go to the draw page and you will see the option to conduct an additional draw.
    </>,
  ),
};

const qPicture = {
  question: _(
    'Как отправить картинку в чат с подопечным/сантой?',
    'How can I send a picture in the chat with my recipient/Santa?',
  ),
  search: 'картинка ссылка файл прикрепить picture link file attach',
  answer: _(
    <>
      Слева от поля сообщения нажмите на иконку скрепки и выберите нужный файл. После выбора файла в
      чат отправится сообщение со ссылкой на него.
    </>,
    <>
      Click on the paperclip icon to the left of the message field and select the desired file.
      After selecting the file, a message with a link to it will be sent in the chat.
    </>,
  ),
};

const qSetWrongEmail = {
  question: _(
    'Я указал(а) неверную почту в карточке, что делать?',
    'I have entered wrong email in my card, what to do?',
  ),
  search: 'указа неверн неверная почт в карточке делать entered wrong email',
  answer: _(
    <>
      Если вы случайно ошиблись в адресе и вам не приходит письмо - сообщите это огранизатору и
      попросите его поменять вам адрес в карточке на верный - письмо переотправится на новый адрес.
    </>,
    <>
      If you accidentally made a mistake in the address and you are not receiving the letter -
      inform the organizer and ask them to change your address in the card to the correct one - the
      letter will be resent to the new address.
    </>,
  ),
};

const qHowToDeleteCard = {
  question: _('Как удалить свою карточку из коробки?', 'How to delete my card from the box?'),
  search: 'удалить удал удалите карточ коробки delete card',
  answer: _(
    <>
      Удалить свою карточку самостоятельно можно только ДО жеребьевки. Чтобы сделать это, перейдите
      в коробку к вашей карточке, нажмите на иконку карандаша - откроется страница редактирования,
      где вы сможете удалить карточку.
      <br />
      Чтобы удалить карточку после проведения жеребьевки обратитесь к вашему организатору коробки.
    </>,
    <>
      You can only delete your card by yourself BEFORE the draw. To do this, go to the box with your
      card, click on the pencil icon - the editing page will open, where you can delete the card.
      <br />
      To delete your card after the draw has been conducted - ask your organizer.
    </>,
  ),
};

const qHowToDeleteParticipantCard = {
  question: _(
    'Как удалить карточку участника из коробки?',
    'How to delete participant card from the box?',
  ),
  search: 'удал удалить удал удалите карточку участника коробки delete participant card',
  answer: _(
    <>
      Чтобы удалить карточку участника, перейдите к ней, нажав на нее на странице участников.
      Прокрутите вниз и увидите секцию для удаления.
      <br />
      <b>ВАЖНО!</b> если вы удаляете карточку после проведения жеребьевки, то у Тайного Санты
      удаленного участника сменится подопечный.
    </>,
    <>
      To delete a participant's card, go to it by clicking on it on the participants page. Scroll
      down and you will see a section for deletion.
      <br />
      <b>IMPORTANT!</b> if you delete a card after the draw, the Secret Santa of the deleted
      participant will have a different recipient.
    </>,
  ),
};

const qFacebook = {
  question: _(
    'Я заходил на сайт с помощью запрещенной сети Facebook, а теперь не могу, что делать?',
    'I was signing in using restricted Facebook site, but cannot login now, what to do?',
  ),
  search: 'заходил помощью запрещенной сеть Facebook фейсбук могу Facebook cannot login',
  answer: _(
    <>
      Если ваш аккаунт FB имеет привязанную почту, вы можете восстановить доступ тут{' '}
      <TextMedium orange>
        <SimpleLink href="https://santa-secret.ru/recover">
          https://santa-secret.ru/recover
        </SimpleLink>
      </TextMedium>
      <br />
      Если к аккаунту не была привязана почта, то отправьте письмо на <b>
        postman@santa-secret.ru
      </b>{' '}
      со следующим сообщением:
      <br />
      <br /> "Прошу восстановить мне доступ к сайту на данный email адрес, так как раньше я
      использовал(а) вход через FB."
      <br />
      <br />
      Так же, вы можете воспользоваться международной версией сайта{' '}
      <TextMedium orange>
        <SimpleLink href="https://santa-secret.com" target={'_blank'}>
          https://santa-secret.com
        </SimpleLink>
      </TextMedium>
    </>,
    <>
      If your FB account has an associated email, you can recover access here{' '}
      <TextMedium orange>
        <SimpleLink href="https://santa-secret.ru/recover">
          https://santa-secret.ru/recover
        </SimpleLink>
      </TextMedium>
      <br />
      If no email was linked to the account, then send an email to <b>
        postman@santa-secret.ru
      </b>{' '}
      with the following message:
      <br />
      <br /> 'Please restore my access to the site to this email address, as I previously used FB
      login.'
      <br />
      <br />
      You can also use international site version{' '}
      <TextMedium orange>
        <SimpleLink href="https://santa-secret.com" target={'_blank'}>
          https://santa-secret.com
        </SimpleLink>
      </TextMedium>
    </>,
  ),
};

const qSecondOrganizer = {
  question: _(
    'Можно ли добавить второго организатора в коробку?',
    'Can I add additional organizer to the box?',
  ),
  search: 'организ организатора организатором добав добавить organizer organi',
  answer: _(
    <>Добавить второго организатора в коробку на данный момент нельзя.</>,
    <>Currently, you cannot add additional organizer to the box.</>,
  ),
};

const qChangeOrganizer = {
  question: _('Можно ли сменить организатора коробки?', 'Can I change organizer of the box?'),
  search:
    'организ организатора организатором сменить заменить замен помен поменять добав добавить organizer organi change',
  answer: _(
    <>
      Если вам необходимо поменять организатора и вы сами организатор, отправьте письмо на{' '}
      <b>postman@santa-secret.ru</b> с указанием следующей информации: ссылка на коробку, адрес
      участника на которого нужно поменять. Участник должен быть зарегистрирован на сайте
    </>,
    <>
      If you need to change the organizer and you are the organizer yourself, send an email to{' '}
      <b>postman@santa-secret.ru</b> with the following information: link to the box, address of the
      participant who needs to be changed. The participant must be registered on the site.
    </>,
  ),
};

const qRegisterKids = {
  question: _(
    'Как зарегистрировать детей без email?',
    "How to register children that don't have email?",
  ),
  search:
    'дети детей ребенок ребенка регистрировать регистрация зарегистрир зарегистрировать register kids children',
  answer: _(
    <>
      Чтобы зарегистрировать детей у которых нет собственного email адреса, вы можете использовать
      следующий способ. Пригласите ребенка на адрес youremail<b>+kidname</b>@gmail.com, где
      youremail@gmail.com - ваш электронный адрес. <br />
      Например, если бы ваш адрес был: marina@gmail.ru, а ребенка звали Дима, то можно было бы
      использовать следующий адрес marina+dima@gmail.ru.
      <br />
      Таким образом у детей будет отдельный аккаунт на сайте, но все уведомления будут приходить на
      вашу почту.
    </>,
    <>
      To register children who do not have their own email address, you can use the following
      method. Invite the child to the following address youremail<b>+kidname</b>@gmail.com, where
      youremail@gmail.com is your address. <br />
      For example, if your address was marina@gmail.ru, and the child's name was Dima, you could use
      the address marina+dima@gmail.ru.
      <br />
      This way, children will have a separate account on the site, but all notifications will come
      to your email.
    </>,
  ),
};

const qWillUsersKnow = {
  question: _(
    'Узнают ли участники о настройках жеребьевки?',
    'Will participants know about draw settings?',
  ),
  search: 'узнают узна знать уведомлени подопечн настройки жеребьевка',
  answer: _(
    <>Участники не узнают о том, что вы как либо настраивали жеребьевку.</>,
    <>Participants will not know about any settings you used for the draw.</>,
  ),
};
//
// const qChangeGiftee = {
//   question: _('Можно ли сменить подопечного?', 'Can I change giftee?'),
//   search: 'сменить заменить замен помен поменять подопеч подопечного change giftee',
//   answer: _(
//     <>
//       На данный момент сменить подопечного нельзя даже с помощью организатора. Если вам{' '}
//       <b>необходимо</b> поменять подопечного, организатор должен написать на{' '}
//       <b>postman@santa-secret.ru</b> с указанием следующей информации: ссылка на коробку, имя
//       (email) участника, имя (email) желаемого подопечного. Учтите, что у "бывшего" подопечного тоже
//       поменяется Тайный Санта на того, кто был у желаемого подопечного.
//     </>,
//     <>
//       If you need to change the organizer and you are the organizer yourself, send an email to{' '}
//       <b>postman@santa-secret.ru</b> with the following information: link to the box, address of the
//       participant who needs to be changed. The participant must be registered on the site.
//     </>,
//   ),
// };

const homeQuestions = [
  qWhatIsSanta,
  qHowToPlay,
  qMoney,
  qWhatIsTheBox,
  qWhoIsOrganizer,
  qBoxLimit,
  qCanIDrawWithoutRegister,
  qWhatIsThisSiteFor,
];

export const QUESTIONS_TRANSLATIONS = {
  faq_search: _('Поиск по вопросам', 'Search for questions'),
  faq_more_questions_title: _(
    'Остались вопросы или есть предложение?',
    'Have more questions or suggestions?',
  ),
  faq_more_questions_message: _(
    'Отправьте свой вопрос нам на почту, и мы постараемся ответить как можно скорее.',
    'Send your question to us by email, and we will try to respond as soon as possible.',
  ),
  faq_more_questions_write: _('Написать', 'Write'),
  faq_title: _('Часто задаваемые вопросы', 'Frequently Asked Questions'),
  questions: [
    {
      id: 'orgs',
      title: _('Для организаторов', 'For organizers'),
      questions: [
        qBoxLimit,
        qNoEmailInboxOrg,
        qOrgCanSetupDraw,
        qDrawAnonymous,
        qChangeOrganizer,
        qSecondOrganizer,
        qAddUsers,
        qRegisterKids,
        qAdditionalDraw,
        qHowToDeleteParticipantCard,
        qEditDeleteUser,
        qDeleteUserAfterDraw,
        qHowToKnowSantas,
        qDownloadExcel,
        qOrgCannotSeeSettings,
        qOrgCanParticipate,
        qWaitingConfirmation,
      ],
    },
    {
      id: 'common',
      title: _('Общие вопросы', 'Common questions'),
      questions: [
        qSetWrongEmail,
        qNoEmailInbox,
        qWithoutEmail,
        qWhoToAskQuestions,
        qHowToChangeCard,
        qHowToDeleteCard,
        qWaitingConfirmation,
        qChangedMyMind,
        qNoPostalAddress,
        qWhenIWillKnowWard,
        qWillIKnowMySanta,
        qChatAnonymous,
        qPicture,
        qCanSantaBeWard,
        qOddNumber,
        qCanIDrawWithoutRegister,
        qMinUsers,
        qMultipleBoxes,
        qAdminVsParticipantBoxes,
        qFacebook,
      ],
    },
    {
      id: 'service',
      title: _('О сервисе', 'About website'),
      questions: [
        qWhatIsSanta,
        qWhatIsThisSiteFor,
        qWhatIsTheBox,
        qWhoIsOrganizer,
        qHowToPlay,
        qMoney,
      ],
    },
  ],
  home_questions: homeQuestions,
};
