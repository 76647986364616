import * as React from 'react';
import { useEffect, useState } from 'react';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { Switch } from 'react-router';
import { HomePage } from '@src/pages/HomePage';
import { LoginPage } from '@src/pages/auth/LoginPage';
import { RecoverPage } from '@src/pages/auth/RecoverPage';
import { RegisterPage } from '@src/pages/auth/RegisterPage';
import { ResetPasswordPage } from '@src/pages/auth/ResetPasswordPage';
import {
  actionAppSetUrlParams,
  actionAppUpdateState,
  actionLoadAppState,
  CssProps,
  dispatchActionAppUpdateState,
  dispatchActionResetNewBox,
  resetBox,
  SantaAppState,
  store,
} from '@src/state';
import { connect } from 'react-redux';
import { Lang } from '@src/types/lang';
import { RandomizerPage } from '@src/pages/randomizer/RandomizerPage';
import { FeedbackPage } from '@src/pages/FeedbackPage';
import { RandomizerResultPage } from '@src/pages/randomizer/RandomizerResultsPage';
import { AccountPage } from '@src/pages/account/AccountPage';
import { FAQPage } from '@src/pages/FAQPage';
import { NewBoxPage } from '@src/pages/new_box/NewBoxPage';
import { BoxPage } from '@src/pages/box/BoxPage';
import { SantaLocation } from '@src/state/location';
import { resetEditCard, resetEditOtherCard } from '@src/pages/box/card/cardHelpers';
import { MoveDetector } from '@src/MoveDetector';
import * as moment from 'moment';
import { DonatePage } from '@src/pages/DonatePage';
import { DonateSuccessPage } from '@src/pages/DonateSuccessPage';
import { PolicyPage } from '@src/pages/policy/PolicyPage';
import {
  ButtonService,
  ColumnCenter,
  PandaWithGift,
  PictureNotice,
  RowCenter,
  SimpleLink,
  TextMedium,
} from '@src/components';
import { t } from '@src/translations';
import { BoxesPage } from '@src/pages/boxes/BoxesPage';
import updateMetaTags from '@src/meta';
import { GiftsPage } from '@src/pages/GiftsPage';
import { ExamplePage } from '@src/pages/ExamplePage';
import { AdLookRlf } from '@src/components/AdLookRlf';
import { ResendEmailPage } from '@src/pages/ResendEmailPage';
import { reachGoalYaMetrika } from '@src/utils/metrika';
import { getCookie } from '@src/utils/cookie';
import { isDotCom } from '@src/host';

require('@lub0v/ui-components/build/styles.css');

const BOX_PATH = '/box/:key([0-9a-zA-Z\\_\\-&]+)';

type AppProps = {
  language: Lang;
  isMaintenance: boolean;
  urlParams: any;
  isNewCard: boolean;
  css: CssProps;
};

function getCookieAgreement() {
  const val = window.localStorage.getItem('cookie_agreement');
  return !!val;
}

function setCookieAgreement(val) {
  window.localStorage.setItem('cookie_agreement', val);
}

function AppFunc(props: AppProps) {
  const [closedNotice, setClosedNotice] = useState(true);
  const [showCookieAgreement, setShowCookieAgreement] = useState(false);
  const location = useLocation();
  const history = useHistory();

  const [prev, setPrev] = useState(null);

  useEffect(() => {
    onResize();
    moment.locale(isDotCom() ? 'en' : 'ru');
    dispatchActionAppUpdateState({
      isMaintenance: false, // getCookie('unlock') !== 'unlock', // ulock val: U2FsdGVkX1%2FLj9lLq46TpIKVyGVodk6%2BIOPhr20%2FPFw%3D
    });
    updateMetaTags(location.pathname);
    const show = !isDotCom() && !getCookieAgreement();
    setShowCookieAgreement(show);
  }, []);

  const onResize = () => {
    const mobile = document.body.className.includes('mobile');
    const style = window.getComputedStyle(document.body, null).getPropertyValue('font-size');
    const fontSize = parseFloat(style);
    store.dispatch(actionAppUpdateState({ css: { fontSize, mobile } }));
  };

  const closeCookies = () => {
    reachGoalYaMetrika('cookies_closed');
    setCookieAgreement(true);
    setShowCookieAgreement(false);
  };
  const getUrlParams = () => {
    const query = new URLSearchParams(location.search);
    const urlParams: any = {};
    query.forEach((v, k) => {
      urlParams[k] = v;
    });
    return urlParams;
  };

  const handleLoginRedirect = () => {
    const prevLocation = new SantaLocation(prev);
    const loc = new SantaLocation(location);
    if (
      loc.isLoginRelatedPage() &&
      !prevLocation.isLoginRelatedPage() &&
      !prevLocation.isPolicyPage()
    ) {
      let redirect = prevLocation.getPathWithQuery();
      if (redirect) {
        if (redirect.includes('error=')) {
          redirect = redirect.substring(0, redirect.indexOf('?'));
        }
        dispatchActionAppUpdateState({
          recover: { redirect },
          register: { redirect },
          login: { redirect },
        });
      }
    }
    if (!loc.isLoginPage()) {
      dispatchActionAppUpdateState({
        login: { password: null },
      });
    }
  };

  const handleBoxState = () => {
    const prevLoc = new SantaLocation(prev);
    const loc = new SantaLocation(location);
    if (prevLoc.getPath() !== loc.getPath()) {
      dispatchActionAppUpdateState({ notifications: { opened: false } });
    }
    if (prevLoc && prevLoc.getPath().endsWith('/box/new') && !loc.getPath().endsWith('/box/new')) {
      dispatchActionResetNewBox();
    }
    if (prevLoc.getPath().endsWith('/card') && !loc.getPath().endsWith('/card')) {
      dispatchActionAppUpdateState({ box: { createCard: { step: -1 }, editCard: null } });
    }
    if (loc.getPath().endsWith('/card')) {
      const redirect = loc.getPathWithQuery();
      dispatchActionAppUpdateState({
        recover: { redirect },
        register: { redirect },
        login: { redirect },
      });
    }
    if (!loc.getPath().endsWith('/card/edit') && !loc.getPath().endsWith('/settings')) {
      dispatchActionAppUpdateState({ box: { saved: '' } });
    }
    if (loc.getPath().endsWith('/card/edit')) {
      resetEditCard();
    }
    if (!loc.getPath().endsWith('/edit')) {
      resetEditOtherCard();
    }
    if (!loc.getPath().includes('/box/')) {
      resetBox();
    }
    updateMetaTags(location.pathname);
  };

  useEffect(() => {
    setPrev(location);
    store.dispatch(actionLoadAppState());
    window.addEventListener('resize', onResize);
  }, []);

  useEffect(() => {
    store.dispatch(actionAppSetUrlParams(getUrlParams()));
    if (prev) {
      handleBoxState();
      handleLoginRedirect();
    }
    setPrev(location);
  }, [location]);

  const updateViads = (show) => {
    const styleElem = document.getElementById('santa-vads-override');
    if (styleElem) {
      styleElem.parentNode.removeChild(styleElem);
    }
    if (!show) {
      document.head.insertAdjacentHTML(
        'beforeend',
        `<style id="santa-vads-override">.vads-positioner { display: none !important; opacity: 0 !important; } .vads-slider { display: none !important; opacity: 0 !important; }</style>`,
      );
    }
  };

  const updateAdPlay = (show) => {
    const styleElem = document.getElementById('santa-adplay-override');
    if (styleElem) {
      styleElem.parentNode.removeChild(styleElem);
    }
    if (!show) {
      document.head.insertAdjacentHTML(
        'beforeend',
        `<style id="santa-adplay-override">.xz-pp-root { display: none !important; opacity: 0 !important; }</style>`,
      );
    }
  };

  const updateAds = (show) => {
    updateViads(show);
    updateAdPlay(show);
  };

  const isShowAd = () => {
    const st = store.getState() as SantaAppState;
    if (!st.showAds) {
      updateAds(false);
      return false;
    }
    const isMobile = document.body.className.includes('mobile');
    const ignoreMobile = ['/box/new', '/feedback', '/gifts', '/login', '/register'];
    const ignoreDesktop = ['/gifts'];
    if (isMobile && ignoreMobile.includes(location.pathname)) {
      updateAds(false);
      return false;
    }
    if (!isMobile && ignoreDesktop.includes(location.pathname)) {
      updateAds(false);
      return false;
    }
    const { box } = st;
    const isCreatingCard = box && box.createCard && box.createCard.step > 0;
    const showAds = !isMobile || !isCreatingCard;
    updateAds(showAds);
    return showAds;
  };

  useEffect(() => {
    updateAds(showAd);
  }, [prev]);

  const showAd = isShowAd();

  if (props.isMaintenance) {
    return (
      <ColumnCenter style={{ marginTop: '5rem' }}>
        <PictureNotice
          translator={t}
          title="notice_site_temporary_off_title"
          hint="notice_site_temporary_off"
        >
          <PandaWithGift />
        </PictureNotice>
      </ColumnCenter>
    );
  }

  const key = location.pathname.startsWith('/box')
    ? location.pathname === '/box/new'
      ? '/box/new'
      : 'box'
    : location.pathname;

  const gifts = <Route path="/gifts" exact component={GiftsPage} />;
  return (
    <>
      {closedNotice ? null : (
        <RowCenter
          className="notice-maintenance"
          style={{
            zIndex: 150,
            position: 'fixed',
            top: '3rem',
            width: '100%',
            height: '130px',
            background: '#faf2f2',
            padding: '0.6rem',
          }}
        >
          <TextMedium orange style={{ width: '80%' }}>
            Письма могут доставляться с большой задержкой из-за проблем у провайдера. Проблема скоро
            должна решиться. Пожалуйста, не отправляйте письма повторно и по возможности используйте
            другие методы оповещений, которые можно указать в настройках профиля. Спасибо за
            понимание и приносим извинения за неудобства.{' '}
            <TextMedium orange underline onClick={() => setClosedNotice(true)}>
              закрыть сообщение
            </TextMedium>
          </TextMedium>
        </RowCenter>
      )}
      <Switch location={location} key={key}>
        <Route path="/" exact component={HomePage} />
        <Route path="/login" exact component={LoginPage} />
        <Route path="/register" exact component={RegisterPage} />
        <Route path="/recover" exact component={RecoverPage} />
        <Route path="/reset_password" exact component={ResetPasswordPage} />
        <Route path="/faq" exact component={FAQPage} />
        <Route path="/feedback" exact component={FeedbackPage} />
        <Route path="/resend" exact component={ResendEmailPage} />
        {gifts}
        <Route path="/policy" exact component={PolicyPage} />
        <Route path="/privacy_policy" exact component={PolicyPage} />
        {isDotCom() ? null : <Route path="/donate/thanks" exact component={DonateSuccessPage} />}
        {isDotCom() ? null : <Route path="/donate" exact component={DonatePage} />}
        <Route path="/randomizer/:uuid([0-9a-zA-Z\-]+)" exact component={RandomizerResultPage} />
        <Route path="/randomizer" exact component={RandomizerPage} />
        <Route path="/account/boxes" exact component={BoxesPage} />
        <Route path="/account" exact component={AccountPage} />
        <Route path="/example" exact component={ExamplePage} />
        <Route path="/box/new" exact component={NewBoxPage} />
        <Route path={BOX_PATH} component={BoxPage} />
        <Route component={HomePage} />
      </Switch>
      <MoveDetector />
      {showCookieAgreement ? (
        <div
          className="cookie_agreement"
          style={{
            position: 'fixed',
            zIndex: 10000000000000,
            bottom: '0',
            left: '0',
            borderRadius: '1rem',
            background: '#67568c',
            height: props.css.mobile ? '10rem' : '4.5rem',
            width: '100%',
            border: '1px solid #fff',
          }}
        >
          <ColumnCenter>
            <RowCenter style={{ padding: '1rem', height: props.css.mobile ? '10rem' : '4.5rem' }}>
              <TextMedium white>
                Наш сайт использует{' '}
                <SimpleLink
                  href="https://static-santa-secret-ru.b-cdn.net/CookiesAgreement.pdf"
                  target="_blank"
                  onClick={() => reachGoalYaMetrika('cookies_clicked')}
                >
                  <TextMedium white underline>
                    файлы cookies.
                  </TextMedium>
                </SimpleLink>{' '}
                Оставаясь на сайте, вы даете согласие на их использование.{' '}
              </TextMedium>
              <ButtonService style={{ marginLeft: '1rem' }} onClick={closeCookies}>
                Хорошо
              </ButtonService>
            </RowCenter>
          </ColumnCenter>
        </div>
      ) : null}
    </>
  );
}

function mapStateToProps(state: SantaAppState) {
  return {
    language: state.language,
    isMaintenance: state.isMaintenance,
    urlParams: state.urlParams,
    css: state.css,
    isNewCard: state.box && state.box.createCard && state.box.createCard.step >= 0,
  };
}

export const App = connect(mapStateToProps)(AppFunc);
