import * as React from 'react';
import { ColumnCenter, RowSpaceAround } from '@src/components';
import { CssProps, SantaAppState } from '@src/state';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import { getApiHost } from '@src/host';

const SRC = 'https://sdk.adlook.tech/inventory/core.js'; // 'https://cdn.adlook.me/js/vbf.js';

type Props = {
  showAds?: boolean;
  numAdsDesktop?: number;
  numAdsMobile?: number;
  css: CssProps;
  row?: boolean;
};

function AdLookIBVComponent({
  showAds = true,
  numAdsDesktop = 1,
  numAdsMobile = 1,
  css,
  row = true,
}: Props) {
  if (!showAds) {
    return null;
  }

  const numAds = css.mobile ? numAdsMobile : numAdsDesktop;
  const ids = getApiHost().includes('localhost')
    ? ['ut-embed', 'ut-embed-1', 'ut-embed-2', 'ut-embed-3']
    : ['ut-embed', 'ut-embed-1', 'ut-embed-2', 'ut-embed-3']; // [24355, 24356, 24357, 24441];

  useEffect(() => {
    const adlookScript = document.querySelector(`script[src="${SRC}"]`);
    if (adlookScript) {
      adlookScript.remove();
    }

    setTimeout(() => {
      const scriptElement = document.createElement('script');
      scriptElement.src = SRC;
      scriptElement.defer = true;
      scriptElement.async = true;
      scriptElement.charset = 'UTF-8';
      document.body.appendChild(scriptElement);
    }, 1000);

    setTimeout(() => {
      ids.forEach((id) => {
        // @ts-ignore
        if (window.UTInventoryCore) {
          // @ts-ignore
          // eslint-disable-next-line no-new
          new window.UTInventoryCore({
            type: 'embed',
            host: 724,
            content: false,
            container: id,
            width: 400,
            height: 225,
            playMode: 'autoplay-visible',
            collapse: 'none',
            infinity: true,
            infinityTimer: 1,
          });
        }
      });
    }, 2000);
  }, []);

  const adBlockDesktop = (id) => (
    <ColumnCenter
      key={id}
      style={{
        marginTop: '2rem',
        marginBottom: '1rem',
        width: '300px',
        background: '',
      }}
    >
      <div id={id} className="adlook_ibv_onlypc"></div>
    </ColumnCenter>
  );

  const adBlockMobile = (id) => (
    <ColumnCenter
      key={id}
      style={{
        marginTop: '2rem',
        marginBottom: '1rem',
        width: '300px',
        background: '',
      }}
    >
      <div id={id} className="adlook_ibv_mobpc"></div>
    </ColumnCenter>
  );

  const ads = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < numAds; i++) {
    if (i < ids.length) {
      if (css.mobile) {
        ads.push(adBlockMobile(ids[i]));
      } else {
        ads.push(adBlockDesktop(ids[i]));
      }
    }
  }
  if (row) {
    return <RowSpaceAround>{ads}</RowSpaceAround>;
  }
  return <ColumnCenter>{ads}</ColumnCenter>;
}

export function mapStateToProps(state: SantaAppState) {
  return {
    css: state.css,
    showAds: state.showAds,
  };
}

export const AdLookIBV = connect(mapStateToProps)(AdLookIBVComponent);
